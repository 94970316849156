import cls from '@/helpers/cls';
import { InputHTMLAttributes, forwardRef } from 'react';

export type TextInputCustomProps = {
  underline?: boolean;
};

export default forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement> & TextInputCustomProps>(function TextInput(
  { type = 'text', className = '', underline, ...props },
  ref,
) {
  return (
    <input
      {...props}
      type={type}
      className={cls(
        'w-full',
        underline
          ? 'border-transparent border-b-white bg-transparent px-0 focus:border-transparent focus:border-b-white focus:ring-0'
          : 'rounded-md border-gray-300 shadow-sm read-only:bg-slate-50 read-only:shadow-none focus:border-primary focus:ring-primary disabled:bg-slate-50 disabled:shadow-none',
        className,
      )}
      ref={ref}
    />
  );
});
